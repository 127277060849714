import TwitchApi from "./twitch-api";

const Utility = {}

Utility.cleanTitle = (title) => {
    return title
        .replace(/[#!]\w+/g, '')        // #blah and !blah
        .replace(/\/\s+\//g, '')        // "/" followed by spaces
        .replace(/\|\s+$/, '')          // "|" followed by spaces
        .replace(/^\s+\|/, '')          // spaces followed by "|"
        .replace(/\s+[-|]\s+$/g, '')    // " - " and " | "
        .replace('[]', '')              // empty square brackets
        .replace('()', '');             // empty parentheses
}

Utility.connectWithTwitch = () => {
    const url = new URL('https://id.twitch.tv/oauth2/authorize');

    url.search = new URLSearchParams({
        client_id: TwitchApi.clientId,
        redirect_uri: `${window.location.origin}/twitch/callback`,
        response_type: 'token',
        scope: 'user_read user:read:follows',
        state: Math.random().toString(36).substring(2)
    });

    location.href = url.toString();
}

Utility.openBlank = (url, target = 'blank') =>
    window.open(url, target);

export default Utility;
