import style from './style.css';
import {useAuth} from "../../hooks/useAuth";

const Authenticate = () => {
    const {user} = useAuth();

    return (
        <div className={style.authenticate}>
            {user &&
                <img
                    className={style['user-profile-image']}
                    alt={"You"}
                    src={user.profileImageUrl}
                />
            }
        </div>
    );
};

export default Authenticate;
