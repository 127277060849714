import style from './style.css';
import debounce from "lodash.debounce";
import TwitchApi from "../../lib/twitch-api";
import {useEffect, useRef, useState} from "preact/hooks";
import Utility from "../../lib/utility";
import IsoLanguageCodes from "../../lib/iso-language-codes";

const Search = () => {
    const input = useRef(null);
    const searchResults = useRef(null);
    const [channels, setChannels] = useState([]);
    let [onlyLive, setOnlyLive] = useState(true);

    const handleInput = e => {
        if (e.target.value === '') {
            resetInput();
            return;
        }

        search();
    };

    const handleKeypress = e => {
        if (e.key === 'Escape') {
            resetInput();
        }
    };

    const resetInput = () => {
        setChannels([]);
        input.current.value = '';
    }

    const handleClickGame = gameName => {
        window.open(`https://www.twitch.tv/directory/game/${gameName}`, '_blank');
        resetInput();
    };

    const handleClickLive = e => {
        onlyLive = e.target.checked;
        setOnlyLive(e.target.checked);
        search();
    };

    const handleClickChannel = channel => {
        window.open(`https://www.twitch.tv/${channel.displayName}`, '_blank');
        resetInput();
    };

    const search = () => {
        if (input.current.value.length < 3) {
            return;
        }

        // this actually searches channels and categories when live_only is true?
        // https://dev.twitch.tv/docs/api/reference/#search-channels
        TwitchApi.searchChannels(input.current.value, {live_only: onlyLive})
            .then(response => setChannels(response));
    };

    useEffect(() => {
        document.onclick = function (e) {
            if (e.target.id === 'search-input' || searchResults.current?.contains(e.target)) {
                return;
            }

            if (channels.length > 0) {
                resetInput();
            }
        };
    });

    return (
        <>
            <input
                id={'search-input'}
                ref={input}
                type={'text'}
                placeholder={'Search Twitch'}
                className={style.input}
                onInput={debounce(handleInput, 500)}
                onKeyUp={handleKeypress}
            />

            <div
                id={'search-results'}
                ref={searchResults}
                className={style.resultsBox}
                onKeyUp={handleKeypress}
            >
                {channels.length > 0 &&
                    <>
                        <div>
                            <span className={style.heading}>Channels</span>
                            <div className={style.options}>
                                <input
                                    type={"checkbox"}
                                    className={"mr-1"}
                                    checked={onlyLive ? 'checked' : ''}
                                    onClick={e => handleClickLive(e)}
                                /> <label>Only live channels</label>
                            </div>
                        </div>

                        <div className={style.channelsWrapper}>
                            {channels.map(channel => {
                                return (
                                    <div key={channel.id} className={style.channel}>
                                        <img
                                            src={channel.thumbnailUrl}
                                            alt={channel.displayName}
                                            onClick={() => handleClickChannel(channel)}
                                        />

                                        <div className={style.channelDetails}>
                                            <div
                                                className={style.displayName}
                                                onClick={() => handleClickChannel(channel)}
                                            >{channel.displayName}</div>
                                            <div
                                                className={style.title}
                                                onClick={() => handleClickChannel(channel)}
                                                title={channel.title}
                                            >
                                                {channel.broadcasterLanguage !== 'en' &&
                                                    <span
                                                        className={style.language}
                                                        title={IsoLanguageCodes.lookup(channel.broadcasterLanguage)}
                                                    >{channel.broadcasterLanguage}</span>
                                                }
                                                {Utility.cleanTitle(channel.title)}
                                            </div>
                                            {channel.isLive &&
                                                <div
                                                    className={style.gameName}
                                                    onClick={() => handleClickGame(channel.gameName)}
                                                >
                                                    Streaming <span className={"underline cursor-pointer"}>{channel.gameName}</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default Search;
