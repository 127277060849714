import {useUser} from "./useUser";
import {useLocalStorage} from "./useLocalStorage";
import {useEffect} from "preact/hooks";

export const useAuth = () => {
    const {user, addUser, removeUser} = useUser();
    const {getItem} = useLocalStorage();

    useEffect(() => {
        const user = getItem('user');
        if (user) {
            addUser(JSON.parse(user));
        }
    }, []);

    const login = (user) => addUser(user);

    const logout = () => removeUser();

    return {user, login, logout};
}
