import style from './style.css';
const FlyoutMenu = ({children, ...props}) => {
    return (
        <div className={`${style.flyoutMenu} relative`}
             onMouseEnter={() => props.setHovering(true)}
             onMouseLeave={() => props.setHovering(false)}>
            <div className="absolute -left-5 z-10 mt-2 flex w-screen max-w-max -translate-x-[200px] px-4">
                <div
                    className="w-screen max-w-[250px] flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5"
                >
                    <MenuItem
                        title={"Settings"}
                        description={"Manage your settings"}
                        onClick={() => window.location = '/settings'}
                    >
                        <svg className={"h-6"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" />
                        </svg>
                    </MenuItem>
                    <div className="grid grid-cols-1 divide-x divide-gray-900/5 bg-gray-50">
                        <a href="#"
                           className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                           onClick={props.signout}
                        >
                            <svg className={"h-4"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
                            </svg>
                            Sign Out
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MenuItem = ({children, title, description, onClick}) => {
    return (
        <div className="p-4" onClick={onClick}>
            <div className="group relative flex gap-x-6 rounded-lg p-2 hover:bg-lime-50">
                <div
                    className="mt-1 flex h-6 w-6 flex-none items-center justify-center rounded-lg bg-lime-50 group-hover:bg-white">
                    {children}
                </div>
                <div>
                    <a href="#" className="font-semibold text-gray-900">
                        {title}
                        <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 text-gray-600">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default FlyoutMenu;
