import TwitchJs from "twitch-js";
import localforage from "localforage";
import SimpleCache from "../lib/simple-cache";

const TwitchApi = {}

TwitchApi.clientId = 'umgu2q17oevxsj85kkp2b1my3vbajh';

localforage.config({name: 'twitch-api'});

/**
 * Get data from the Twitch API and assemble it into a single object. This
 * is the main entry point for this library.
 *
 * @param user
 * @returns {Promise<unknown>}
 */
TwitchApi.getData = (user) => {
    return new Promise((resolve) => {
        getFollowedUsers(user).then(users => {
            getFollowedStreams(user).then(streams => {
                getGames(streams).then(games => {
                    const data = users.map(user => {
                        const stream = streams.find(stream => stream.userId === user.id);
                        if (stream) {
                            stream['game'] = games.find(game => stream.gameId === game.id);
                        }

                        return {user, stream};
                    });

                    resolve(data);
                });
            });
        });
    });
};

TwitchApi.getUser = () => {
    return new Promise((resolve) => {
        get('users')
            .then(response => resolve(response[0]));
    });
};

TwitchApi.searchCategories = (query) => {
    return new Promise((resolve) => {
        get('search/categories', {search: {query}})
            .then(response => resolve(response));
    });
};

TwitchApi.searchChannels = (query, params) => {
    return new Promise((resolve) => {
        const options = {search: {query, first: 40, ...params}}
        get('search/channels', options)
            .then(response => resolve(response));
    });
};

TwitchApi.getVODs = (user_id) => {
    return new Promise((resolve) => {
        const key = `vods-${user_id}`;

        SimpleCache.get(key).then((value) => {
            if (value) {
                resolve(value);
                return;
            }

            console.log('[getVODs] cache miss', key);
            get('videos', {
                search: {
                    user_id,
                    sort: 'time',
                    type: 'archive',
                    first: 5
                }
            }).then(response => {
                SimpleCache.set(key, response);
                resolve(response)
            });
        });
    });
};


const generateId = (ids) => {
    return ids.reduce((sum, i) => sum + i / 4294967295, 0);
};

const getFollowedUsers = (user) => {
    return new Promise((resolve) => {
        if (!user) {
            resolve([]);
            return;
        }

        get('channels/followed', {search: {user_id: user.id, first: 100}})
            .then(response => {
                const ids = response.map(follow => follow.broadcasterId);
                const key = `users-${generateId(ids)}`;
                SimpleCache.get(key)
                    .then(users => {
                        if (users) {
                            resolve(users);
                            return;
                        }
                        get('users', {search: {id: ids}})
                            .then(response => {
                                const sorted = response.sort((a, b) =>
                                    a.displayName.localeCompare(b.displayName)
                                );

                                SimpleCache.set(key, sorted);
                                resolve(sorted);
                            });
                    });
            });
    });
};

const getFollowedStreams = (user) => {
    return new Promise((resolve) => {
        if (!user) {
            resolve([]);
            return;
        }

        get('streams/followed', {search: {user_id: user.id}})
            .then(response => resolve(response));
    });
};

const getGames = (streams) => {
    return new Promise((resolve) => {
        const gameIds = streams.map(stream => stream.gameId);
        if (gameIds.length === 0) {
            resolve([]);
            return;
        }
        const key = `games-${generateId(gameIds)}`;
        SimpleCache.get(key)
            .then(games => {
                if (games) {
                    resolve(games);
                    return;
                }

                get('games', {search: {id: gameIds}})
                    .then(response => {
                        SimpleCache.set(key, response);
                        resolve(response);
                    });
            });
    });
};

const get = (endpoint, options = {}) => {
    const accessToken = localStorage.getItem('twitch-access-token');

    return new Promise((resolve, reject) => {
        if (!accessToken || !TwitchApi.clientId) {
            console.log(`[TwitchApi.get] no access token or client id accessToken=${accessToken}, clientId=${TwitchApi.clientId}`);
            reject('no access token or client id');
        }

        const {api} = new TwitchJs({token: accessToken, clientId: TwitchApi.clientId});
        api.get(endpoint, options)
            .then(response => resolve(response.data));
    });
};

export default TwitchApi;
