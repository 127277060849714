import style from './style.css';
import Authenticate from "../authenticate";
import Search from "../search";
import FlyoutMenu from "../flyout-menu";
import {useState} from "preact/hooks";
import {useAuth} from "../../hooks/useAuth";

const Header = () => {
    const {user, logout} = useAuth();
    const [showMenu, setShowMenu] = useState(false);
    let closeTimer = null;

    const clearAccessToken = () => {
        logout();
        location.reload();
    };

    const hideMenu = () => {
        closeTimer = setTimeout(() => setShowMenu(false), 1000);
    }

    const unhideMenu = () => {
        if (user) {
            setHovering(true);
        }
    }

    const setHovering = (state) => {
        if (state && closeTimer) {
            clearTimeout(closeTimer);
        }

        setShowMenu(state);
    };

    return (
        <header className={style.header}>
            <div className={style.logoWrapper}>
                <a href="/" className={style.logo}>
                    <img
                        src={'/assets/logo.png'}
                        alt={'Twiatch!'}
                    />
                    <h1>Twiatch!</h1>
                </a>
            </div>
            <div className={style.search}>
                {user && <Search />}
            </div>
            <div className={style.authWrapper}>
                <nav
                    onMouseEnter={() => unhideMenu()}
                    onMouseLeave={() => hideMenu()}
                >
                    <Authenticate setHovering={setHovering} />
                    {showMenu &&
                        <FlyoutMenu
                            setHovering={setHovering}
                            signout={() => clearAccessToken()}
                        />}
                </nav>
            </div>
        </header>
    );
};

export default Header;
