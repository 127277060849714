import localforage from "localforage";
import moment from "moment";

const SimpleCache = {};

SimpleCache.set = (key, value, expiresInMinutes = 60) => {
    const wrapped = {
        expiresAt: moment().add(expiresInMinutes, 'm').unix(),
        value
    };

    localforage.setItem(key, wrapped);
};

SimpleCache.get = (key) => {
    return new Promise((resolve) => {
        localforage.getItem(key)
            .then(response => {
                if (!response) {
                    resolve(null);
                    return;
                }

                if (Date.now() > response.expiresAt * 1000) {
                    localforage.removeItem(key);
                }

                resolve(response.value);
            });
    });
};

export default SimpleCache;
