import {Route, Router} from 'preact-router';

import Header from './header';
import Home from '../routes/home';
import TwitchCallback from "./twitch-callback";
import {UserContext} from "../contexts/userContext";
import {DataContext} from "../contexts/dataContext";
import {useEffect, useRef, useState} from "preact/hooks";
import TwitchApi from "../lib/twitch-api";
import {inject} from '@vercel/analytics';
import Settings from "../routes/settings";
import {useLocalStorage} from "../hooks/useLocalStorage";
import Utility from "../lib/utility";

const App = () => {
    let [user, setUser] = useState(null);
    let firstRun = useRef(true);
    let previousStreams = useRef([]);
    const [data, setData] = useState(null);
    const [sortOptions, setSortOptions] = useState({
        sortBy: 'recent',
        sortOrder: 'asc'
    });
    const {setItem} = useLocalStorage();

    inject(); // vercel analytics

    const notify = (title, options) => {
        if (!('Notification' in window)) {
            return;
        }

        const n = new Notification(title, options);
        n.onclick = () => window.focus();

        if (Notification.permission === 'granted') {
            return n;
        }

        if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    return n;
                }
            });
        }
    }

    useEffect(() => {
        const updateData = () => {
            TwitchApi.getData(user).then(response => {
                setData(response);

                const liveStreams = response.filter(stream => stream.stream);
                liveStreams.forEach(stream => {
                    const now = new Date().toISOString();
                    setItem(`last-seen-${stream.user.id}`, now);
                });

                const newStreams = liveStreams.filter(stream => {
                    const prev = previousStreams.current.find(p =>
                        p.stream?.id === stream.stream.id
                    );
                    if (!prev) {
                        return stream;
                    }
                });
                previousStreams.current = liveStreams;

                if (firstRun.current && liveStreams.length > 0 && previousStreams.current.length > 0) {
                    firstRun.current = false;
                } else if (newStreams.length > 0) {
                    newStreams.map(stream => {
                        notify(
                            `${stream.user.displayName} is live!`,
                            {
                                body: Utility.cleanTitle(stream.stream.title),
                                icon: stream.user.profileImageUrl
                            }
                        );
                    });
                }

                document.title = `${liveStreams.length} - Twiatch!`;
            });
        };

        updateData();

        const dataTimer = setInterval(() => updateData(), 30_000);
        return () => clearInterval(dataTimer);
    }, [user]);

    return (
        <div id="app">
            <UserContext.Provider value={{user, setUser}}>
                <DataContext.Provider value={{
                    data,
                    sortOptions,
                    changeSortOrder: (sortOrder) => {
                        setItem('sort-order', sortOrder);
                        setSortOptions({...sortOptions, sortOrder});
                    },
                    changeSortBy: (sortBy) => {
                        setItem('sort', sortBy);
                        setSortOptions({...sortOptions, sortBy});
                    }
                }}>
                    <Header />
                    <main>
                        <Router>
                            <Home path="/" />
                            <Settings path="/settings" />

                            <Route path="/twitch/callback/" component={TwitchCallback} />
                        </Router>
                    </main>
                </DataContext.Provider>
            </UserContext.Provider>
        </div>
    );
};

export default App;
