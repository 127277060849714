import TwitchApi from "../lib/twitch-api";
import {useAuth} from "../hooks/useAuth";
import {useEffect} from "preact/hooks";

const TwitchCallback = () => {
    // todo verify the initial state we sent to twitch

    const {login} = useAuth();

    useEffect(() => {
        const accessToken = document.location.hash.split('&')[0].split('=')[1];
        localStorage.setItem('twitch-access-token', accessToken);

        TwitchApi.getUser()
            .then(response => {
                console.log('[TwitchCallback] accessToken', accessToken, 'user', response);
                login(response);
                location.href = '/';
            });
    });
}

export default TwitchCallback;
